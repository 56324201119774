.contact-form>div {
  margin-bottom: 1rem;
}

.instagram {
  text-decoration-line: underline;
  text-decoration-style: dotted;
  color: #000;
}

.appointment button {
  height: 3rem;
  border-radius: 0.5rem;
  color: #4b5742;
  font-weight: 600;
  background-color: #fff;
}

.appointment button:hover {
  background-color: #bbc1b9;
}