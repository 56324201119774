
.navigation-wrapper {
    height: auto !important;
    background: #efefef !important;
}
.navigation-wrapper button span{
    font-size: 1rem !important;
    font-family: Raleway; /* font size and family change for home bar*/
}
.navigation-wrapper button{
    background: #efefef;
    align-items: center;
    text-transform: uppercase;
    color: #4b5742 !important;
    margin-bottom: 11px;
    min-width: inherit;
    max-width: inherit;
    text-align: center;
    font-family: Raleway;
    font-weight: 600;
    font-style: normal;
    font-size: 13px;
    letter-spacing: .15em;
    margin-right: 25px;
    @media only screen and (max-width: 1024px) { 
        margin-right: 0px;
    }
   
}
.navigation-wrapper button span:hover{
    color: #75856f;
}

.callMe span:first-child{
    border: 1px solid #ddd;
    background-color: #bbc1b9;
    color: #fff;
    padding: 12px;
    border-radius: 8px;
    font-size: 12px !important;
    text-decoration: underline;
}

/* Mobile stylings */
@media only screen and (max-width: 780px) {
    .callMe span:first-child{
        font-size: 1rem !important;
        text-decoration: none;
    }
  }
  