.left1 {
    margin-bottom: 20px;
}
ul {
    list-style-type: none;
}

.developer{
    text-decoration: none;
    font-family: cursive;
}
