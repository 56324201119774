.telephone-number {
    padding: 15px !important;
    min-width: 200px !important;
    height: 34px;
    border-radius: 10px !important;
    border: 2px solid #eed26e !important;
    text-transform: uppercase;
    text-decoration: none;
    color: #eed26e !important;
}

a.telephone-number:hover{
 background-color: #eed26e;
 color: #fff !important;
}

.menuIcon{
    color: #4b5742 !important;
}

.logo{
    cursor: pointer;
    width: 300px;
}
.appBarStyle{
    flex-direction: row;
    padding: 30px 0% 30px 0%;
    justify-content: space-evenly;
    flex-wrap: nowrap !important;
    
}

@media only screen and (max-width: 1024px) {
    .logo{
      width: 200px;
    }
  }
@media only screen and (min-width: 426px) and (max-width: 768px) {
    
    .appBarStyle{
       flex-direction: column;
       flex-wrap: wrap !important;
       justify-content: center;
    }
  }
@media only screen and (max-width: 425px) {
    
    .appBarStyle{
       flex-direction: column;
       flex-wrap: wrap !important;
       justify-content: space-between;
       padding: 0%;
    }
    .logo{
        width: 100px;
    }
  }
  