* {
  padding: 0;
  margin: 0;
}

body,
.app-bar-background {
  background-color: #efefef !important;
  font-family: Raleway;
  font-size: 12px;
}

.App {
  font-family: Raleway;
  margin: 0 auto;
}

.mainBody{
  padding: 0px 10%;
}

.MuiTypography-root {
  font-family: Raleway !important;
  font-weight: 400;
  font-style: normal;
  font-size: 40px;
  letter-spacing: 0.05em;
  line-height: 1.3em;
  text-transform: none;
}

.footer {
  margin: 0 auto;
  background-color: #bbc1b9;
  margin-top: 16px;
  font-family: Raleway;
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  letter-spacing: 0.035em;
  line-height: 1.7em;
  text-transform: none;
  color: #4a5742;
}

h3,
h5,
h4 {
  color: #4b5742;
  font-style: italic;
}

ul li {
  font-family: Raleway;
  text-transform: capitalize;
}
ul{
  background-color: #efefef;
}

.svgIcon svg {
  width: 100px;
  height: 100px;
}

.borderSpacing {
  margin: 2rem auto;
  width: 50%;
}

/* Mobile stylings */
@media only screen and (max-width: 780px) {
  .mainBody{
    padding: 0px 16px;
  }
  
}
